import React from "react";
import servicedetails1 from "@/images/services/service-details.jpg";
import Img from "./img";

const ServicePost = () => {
  return (
    <div className="service_details_left">
      <Img src={servicedetails1} alt="Service Details Image" />
      <h1>DÉPANNAGE EXPRESS</h1>
      <p>
     Decco Alliance SOS répare, dépanne et si nécessaire remplace les menuiseries Aluminium, PVC et Bois : fenêtres et portes d’entrée.

Si votre menuiserie présente des impacts, elle est sûrement réparable quel que soit le matériau. Les délais sont plus rapides qu’un remplacement complet et en prime, vous conservez votre menuiserie sans en altérer la solidité. Ces procédés reconnus par les assureurs, sont économiques, écologiques et rapides.

      </p>
      <h2>Common Air Conditioner Problems</h2>
      <ul>
        <li>
          <i className="fa fa-check-circle"></i> Réparation de fenêtre

        </li>
        <li>
          <i className="fa fa-check-circle"></i> Réparation de menuiserie extérieure.
        </li>
        <li>
          <i className="fa fa-check-circle"></i> Remplacement de tout vitrage cassé.
        </li>
        <li>
          <i className="fa fa-check-circle"></i> Tout dépannage, tout réglage et toute réparation de fermeture du bâtiment
        </li>
        <li>
          <i className="fa fa-check-circle"></i> Tout problème de serrurerie
        </li>
      </ul>
      <p className="extra_mr">
      Si vous ne trouvez pas votre besoin en réparation de menuiserie parmi cette liste non exhaustive, ne vous inquiétez-pas ! Nous saurons répondre avec professionnalisme à votre besoin de reparation.,{" "}
        <a href="#">click here</a> pour plus d'informations sur notre service de réparation 24h/24 et 7j/7
      </p>
   
    </div>
  );
};

export default ServicePost;
